const dayjs = require('dayjs');

module.exports = {
    // NB. THIS SHOULD BE USED CAREFULLY, ONLY IN SPECIAL CIRCUMSTANCES
    // Please use NavLink or Link instead
    refreshPage() {
        window.location.reload();
    },

    navigateToExternalUrl(url) {
        window.location.replace(url);
    },

    formatPln(amount, defaultValue) {
        let pln = Intl.NumberFormat('pl-PL', {
            style: 'currency',
            currency: 'PLN',
        });

        return isNaN(amount) ? defaultValue : pln.format(amount / 100);
    },

    formatDate(dateStr) {
        return dayjs(dateStr).format('YYYY-MM-DD HH:mm');
    },

    formatDateOnly(dateStr) {
        return dayjs(dateStr).format('YYYY-MM-DD');
    },

    toFee(feeValue): Number {
        return Number(feeValue).toFixed(2) * 100;
    },

    eventStatusToDisplayText(status) {
        switch (status) {
            case 'DRAFT':
                return 'wersja robocza';
            case 'IN_PROGRESS':
                return 'w trakcie';
            case 'COMPLETED':
                return 'zakończono';
            case 'CANCELLED':
                return 'anulowano';
            default:
                return 'brak';
        }
    },

    eventStatusToClass(status) {
        switch (status) {
            case 'DRAFT':
                return 'text-bg-warning';
            case 'IN_PROGRESS':
                return 'text-bg-primary';
            case 'COMPLETED':
                return 'text-bg-success';
            case 'CANCELLED':
                return 'text-bg-dark';
            default:
                return 'text-bg-dark';
        }
    },

    eventRegisterStatusToClass(status) {
        switch (status) {
            case 'DRAFT':
                return 'text-bg-warning';
            case 'UNPAID':
                return 'text-bg-danger';
            case 'PAYMENT_NOT_REQUIRED':
                return 'text-bg-success';
            case 'COMPLETED_UNPAID':
                return 'text-bg-secondary';
            case 'PAID':
                return 'text-bg-success';
            case 'PAID_CASH':
                return 'text-bg-success';
            case 'PAID_BANK_TRANSFER':
                return 'text-bg-success';
            case 'PAID_OTHER':
                return 'text-bg-success';
            case 'CANCELLED':
                return 'text-bg-secondary';
            case 'WONT_PAY':
                return 'text-bg-secondary';
            case 'WITHDRAWN':
                return 'text-bg-success';
            default:
                return 'text-bg-secondary';
        }
    },

    eventRegisterStatusToDisplayText(status) {
        switch (status) {
            case 'DRAFT':
                return 'wersja robocza';
            case 'UNPAID':
                return 'niezapłacono';
            case 'PAYMENT_NOT_REQUIRED':
                return 'nie wymaga płatności';
            case 'COMPLETED_UNPAID':
                return 'niezapłacono';
            case 'PAID':
                return 'zapłacono';
            case 'PAID_CASH':
                return 'gotówka';
            case 'PAID_BANK_TRANSFER':
                return 'przelew';
            case 'PAID_OTHER':
                return 'inna płatność';
            case 'CANCELLED':
                return 'anulowano';
            case 'WONT_PAY':
                return 'odrzucono';
            case 'WITHDRAWN':
                return 'wypłacono';
            default:
                return 'brak';
        }
    },

    messageStatusToDisplayText(status) {
        switch (status) {
            case 'QUEUED':
                return 'oczekuje';
            case 'SEND_REQUESTED':
                return 'wysłany';
            case 'SENT':
                return 'wysłany';
            case 'DELIVERED':
                return 'dostarczony';
            case 'UNDELIVERED':
                return 'niedostarczony';
            case 'FAILED':
                return 'błąd';
            default:
                return 'nieznany';
        }
    },

    messageStatusToClass(status) {
        switch (status) {
            case 'QUEUED':
                return 'text-bg-secondary';
            case 'SEND_REQUESTED':
                return 'text-bg-secondary';
            case 'SENT':
                return 'text-bg-primary';
            case 'DELIVERED':
                return 'text-bg-success';
            case 'UNDELIVERED':
                return 'text-bg-danger';
            case 'FAILED':
                return 'text-bg-danger';
            default:
                return 'text-bg-dark';
        }
    },

    enrolmentStatusToDisplayText(status) {
        switch (status) {
            case 'DRAFT':
                return 'wersja robocza';
            case 'IN_PROGRESS':
                return 'w trakcie';
            case 'ENDED':
                return 'zakończono';
            case 'ARCHIVED':
                return 'archiwalne';
            default:
                return 'brak';
        }
    },

    enrolmentStatusToClass(status) {
        switch (status) {
            case 'DRAFT':
                return 'text-bg-warning';
            case 'IN_PROGRESS':
                return 'text-bg-success';
            case 'ENDED':
                return 'text-bg-primary';
            case 'ARCHIVED':
                return 'text-bg-light';
            default:
                return 'text-bg-dark';
        }
    },

    formatFromE164(numberE164: string, defaultValue: string) {
        if (!numberE164) {
            return defaultValue || '-';
        }

        let cleaned = ('' + numberE164).replace(/\D/g, '');

        let match =
            cleaned.length === 11
                ? cleaned.match(/^(\d{2})(\d{3})(\d{3})(\d{3})$/)
                : cleaned.match(/^(\d{2})(\d{3})(\d{3})(\d{4})$/);

        if (match) {
            let intlCode = '+' + match[1];
            return [intlCode, ' ', match[2], ' ', match[3], ' ', match[4]].join('');
        }

        return defaultValue ? defaultValue : '-';
    },

    removeAllWhitespace(str) {
        return str && str.trim().length > 0 ? str.replaceAll(/\s/g, '') : str;
    },

    getLocale() {
        return { languageCode: 'pl', region: 'PL' };
    },

    incomeType(type) {
        switch (type) {
            case 'CASH':
                return 'Gotówka';
            case 'ONLINE':
                return 'Online';
            case 'TRANSFER':
                return 'Przelew';
            case 'OTHER':
                return 'Inne';
            default:
                return 'brak';
        }
    },

    enrolmentCategoryToClass(category) {
        switch (category) {
            case 'UNKNOWN':
                return 'nieznane';
            case 'OTHER':
                return 'inne';
            case 'CODING':
                return 'Kodowanie';
            case 'ROBOTICS':
                return 'Robotyka';
            case 'SWIMMING':
                return 'Swimming';
            case 'LINGUISTICS':
                return 'Języki obce';
            case 'MARTIAL_ARTS':
                return 'Karate';
            default:
                return 'brak';
        }
    },
};
