import { apiClient } from '../../apiClientV1';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { SPToastContainer } from '../common/SPToastContainer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../../common.css';
import { InputTextArea } from '../common/InputTextArea';
import { InputTextBox } from '../common/InputTextBox';
import { Toggler } from '../common/Toggler';

interface Props {
    pageTitle: string;
    user: any;
    refreshViewCallback: () => void;
}

const DEFAULT_LOGO_URL = 'https://res.cloudinary.com/dyqm44qjp/image/upload/v1726782139/szkolneplatnosci.pl/graduation-cap-solid.svg.svg';

const initialValues = () => {
    return {
        orgProfileName: '',
        description: '',
    };
};

export const OrganisationProfileSettings = ({ pageTitle, user, refreshViewCallback }: Props) => {
    const { getAccessTokenSilently } = useAuth0();
    const [profile, setProfile] = useState();
    const [payload, setPayload] = useState(initialValues());
    const [pageState, setPageState] = useState('VIEW');
    const [errors] = useState({});
    const [reload, setReload] = useState(false);

    useEffect(() => {
        getAccessTokenSilently().then((accessToken) => {
            apiClient(accessToken)
                .get('/organisation-profiles/_self')
                .then((res) => setProfile(res.data));
        });
    }, [getAccessTokenSilently, reload]);

    function refresh() {
        setReload(!reload);
    }

    function copyLink(publicProfileUrl) {
        navigator.clipboard
            .writeText(publicProfileUrl?.href)
            .then(() => toast.success('Skopiowano pomyślnie!'))
            .catch(() => toast.error('Nie udało skopiować się linku'));
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setPayload({
            ...payload,
            [name]: value,
        });
    };

    function handleCancel() {
        setPayload(initialValues());
        setPageState('VIEW');
    }

    function handleProfileCreate(orgProfile) {
        getAccessTokenSilently().then((accessToken) => {
            const request = { ...orgProfile, logoUrl: DEFAULT_LOGO_URL };
            apiClient(accessToken)
                .post('/organisation-profiles', request)
                .then(() => refresh());
        });
    }

    function handleProfileUpdated(publicIdentifier, status) {
        getAccessTokenSilently().then((accessToken) => {
            const request = {
                action: 'UPDATE_STATUS',
                status: status,
            };

            apiClient(accessToken)
                .patch(`/organisation-profiles/${publicIdentifier}`, request)
                .then(() => refresh());
        });
    }

    function statusBadge(status) {
        return <div className={`badge rounded-pill ${statusToClass(status)}`}>{statusToLabel(status)}</div>;
    }

    function statusToLabel(status) {
        switch (status) {
            case 'DRAFT':
                return 'Wersja robocza';
            case 'PUBLIC':
                return 'Opublikowany';
            case 'BLOCKED':
                return 'Zablokowany';
            default:
                return 'nieznany';
        }
    }

    function statusToClass(status) {
        switch (status) {
            case 'DRAFT':
                return 'text-bg-warning';
            case 'PUBLIC':
                return 'text-bg-success';
            case 'BLOCKED':
                return 'text-bg-danger';
            default:
                return 'text-bg-dark';
        }
    }

    return (
        user &&
        user.permissions?.canReadEnrolments && (
            <>
                <div className="card">
                    <SPToastContainer />

                    <div className="card-header">
                        <div className="d-flex flex-row justify-content-between">
                            <div className="d-flex gap-2">
                                <div>{pageTitle}</div>
                                {profile && <div>{statusBadge(profile.status)}</div>}
                            </div>

                            <div className="d-flex gap-2">
                                {profile && (
                                    <>
                                        <Toggler condition={profile.status === 'DRAFT'}>
                                            <button
                                                className="btn btn-sm btn-success"
                                                onClick={() => handleProfileUpdated(profile.publicIdentifier, 'PUBLIC')}
                                            >
                                                Opublikuj profil
                                            </button>
                                        </Toggler>
                                        <Toggler condition={profile.status === 'PUBLIC'}>
                                            <button
                                                className="btn btn-sm btn-warning"
                                                onClick={() => handleProfileUpdated(profile.publicIdentifier, 'DRAFT')}
                                            >
                                                Wersja robocza
                                            </button>
                                        </Toggler>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>

                    {profile ? (
                        <div className="card-body">
                            <div>
                                <div className="d-flex align-items-center gap-5">
                                    <img
                                        className="img-fluid img-round-200 p-3"
                                        src={profile.logoUrl}
                                        alt={profile.profileName}
                                    />
                                    <p className="fs-1">{profile.profileName}</p>
                                </div>

                                <p className="fs-5 display-linebreak">{profile.description}</p>

                                {profile?._links?.publicProfileUrl && (
                                    <Toggler condition={profile.status === 'PUBLIC'}>
                                        <div className="d-flex gap-2 align-items-center">
                                            <a href={profile._links.publicProfileUrl.href} target="_blank"
                                               rel="noreferrer">
                                                Zobacz swój profil
                                            </a>
                                            <FontAwesomeIcon icon="fa-solid fa-arrow-up-right-from-square" />

                                            <div className="text-dark">|</div>

                                            <div
                                                className="text-primary pointer"
                                                onClick={() => copyLink(profile._links.publicProfileUrl)}
                                            >
                                                Skopiuj do schowka
                                            </div>
                                        </div>
                                    </Toggler>
                                )}
                            </div>
                        </div>
                    ) : (
                        <div className="text-center m-3">
                            {pageState === 'VIEW' && (
                                <>
                                    <p>
                                        Aby zbierać zapisy online, potrzebny jest profil publiczny Twojej organizacji.
                                    </p>
                                    <small>
                                        <p>Możesz utworzyć nowy profil klikając poniżej.</p>
                                    </small>

                                    <button className="btn btn-primary" onClick={() => setPageState('CREATE-PROFILE')}>
                                        Utwórz profil publiczny
                                    </button>
                                </>
                            )}

                            {pageState === 'CREATE-PROFILE' && (
                                <>
                                    <p className="fs-3 fw-bold">Utwórz profil</p>

                                    <div className="d-flex justify-content-center">
                                        <div className="d-flex flex-column gap-2 m-2 w-75">
                                            <InputTextBox
                                                inputValues={payload}
                                                inputName="orgProfileName"
                                                inputHandler={handleInputChange}
                                                placeholder="Nazwa profilu"
                                                errors={errors}
                                            />

                                            <InputTextArea
                                                inputValues={payload}
                                                inputName="description"
                                                inputHandler={handleInputChange}
                                                placeholder="Opis profilu"
                                                errors={errors}
                                            />
                                        </div>
                                    </div>

                                    <div className="d-flex justify-content-center">
                                        <div className="d-flex gap-2">
                                            <button className="btn btn-light" onClick={handleCancel}>
                                                Anuluj
                                            </button>
                                            <button
                                                className="btn btn-success"
                                                onClick={() => handleProfileCreate(payload)}
                                            >
                                                Potwierdź
                                            </button>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    )}
                </div>
            </>
        )
    );
};
