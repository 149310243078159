import React, { useCallback, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { apiClient } from '../../apiClientV1';
import dayjs from 'dayjs';
import _ from 'lodash';
import '../../common.css';
import ReactApexChart from 'react-apexcharts';
import { Toggler } from '../common/Toggler';

const initialChartStateWithData = (seriesData, categoriesData) => {
    return {
        series: [{
            name: 'Frekwencja',
            data: seriesData,
        }],
        options: {
            chart: {
                type: 'bar',
                height: 400,
            },
            plotOptions: {
                bar: {
                    dataLabels: {
                        position: 'top', // top, center, bottom
                    },
                    colors: {
                        ranges: [{
                            from: 0,
                            to: 50,
                            color: '#F15B46',
                        }, {
                            from: 50,
                            to: 75,
                            color: '#FEB019',
                        }],
                    },
                    columnWidth: '75%',
                },
            },
            yaxis: {
                title: {
                    text: 'Frekwencja',
                },
                labels: {
                    formatter: function(y) {
                        return y + '%';
                    },
                },
            },
            xaxis: {
                categories: categoriesData,
                labels: {
                    rotate: -45,
                },
            },
        },
    };
};

export const AttendanceBreakdownReport = () => {
    const { getAccessTokenSilently } = useAuth0();

    const [chartState, setChartState] = useState(initialChartStateWithData([], []));
    const [studentGroups, setStudentGroups] = useState(null);
    const [selectedStudentGroup, setSelectedStudentGroup] = useState('');

    const monthAgo = dayjs().add(-1, 'month').startOf('day');
    const threeMonthsAgo = dayjs().add(-3, 'month').startOf('day');
    const firstOfTheMonth = dayjs().startOf('month').startOf('day');
    const endOfDayToday = dayjs().endOf('day');

    const [startDate, setStartDate] = useState(monthAgo);
    const [endDate] = useState(endOfDayToday);

    const formatDate = (dateStr) => {
        return dayjs(dateStr).format('YYYY-MM-DD');
    };

    const toPolishMonth = (month) => {
        switch (month) {
            case 'Jan':
                return 'Sty';
            case 'Feb':
                return 'Lut';
            case 'Mar':
                return 'Mar';
            case 'Apr':
                return 'Kwi';
            case 'May':
                return 'Maj';
            case 'Jun':
                return 'Cze';
            case 'Jul':
                return 'Lip';
            case 'Aug':
                return 'Sie';
            case 'Sep':
                return 'Wrz';
            case 'Oct':
                return 'Paź';
            case 'Nov':
                return 'Lis';
            case 'Dec':
                return 'Gru';
            default:
                return month;
        }
    };

    const fetchStudentGroups = useCallback(() => {
        getAccessTokenSilently()
            .then(accessToken => {
                apiClient(accessToken).get('/student-groups')
                    .then(res => setStudentGroups(_.orderBy(res.data, ['displayName'], ['asc'])));
            });
    }, [getAccessTokenSilently]);

    const fetchData = useCallback((studentGroupId, startDate, endDate) => {
        const formatChartDate = (dateStr) => {
            const date = dayjs(dateStr);
            const month = date.format('MMM');
            const temp = date.format('D #@HH:mm');

            return temp.replace('#', toPolishMonth(month));
        };

        getAccessTokenSilently()
            .then(accessToken => {
                const fromDate = startDate.toISOString();
                const toDate = endDate.toISOString();

                apiClient(accessToken).get(`/reports/attendance-breakdown?student-group-id=${studentGroupId}&from=${fromDate}&to=${toDate}`)
                    .then(res => {
                        const series = [];
                        const categories = [];

                        const sorted = _.orderBy(res.data?.attendance, ['eventDate'], ['asc']);

                        sorted.forEach(item => {
                            series.push(item.attendancePercentage);
                            categories.push(formatChartDate(item.eventDate));
                        });

                        setChartState(initialChartStateWithData(series, categories));
                    });
            });
    }, [getAccessTokenSilently]);

    const onStartDateChanged = (newStartDate) => {
        setStartDate(newStartDate);
        fetchData(selectedStudentGroup, newStartDate, endDate);
    };

    const onStudentGroupSelectionChanged = (e) => {
        setSelectedStudentGroup(e.target.value);
        fetchData(e.target.value, startDate, endDate);
    };

    useEffect(() => {
        fetchStudentGroups();
        fetchData(selectedStudentGroup, startDate, endDate);
    }, [endDate, fetchData, fetchStudentGroups, selectedStudentGroup, startDate]);

    return (
        <div className="d-flex flex-column align-items-center">
            <div className="fs-1">Obecność</div>

            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-sm-10 col-md-8 col-lg-6">
                        {/************ STUDENT GROUPS ************/}
                        <div className="form-floating m-5">
                            <select className="form-select"
                                    id="studentGroupIdSelect"
                                    value={selectedStudentGroup}
                                    onChange={onStudentGroupSelectionChanged}>
                                <option value="" disabled={true}>Wybierz Grupę</option>
                                <Toggler condition={!!studentGroups}>
                                    {studentGroups?.map(studentGroup =>
                                        <option key={studentGroup.id}
                                                value={studentGroup.id}>{studentGroup.displayName}</option>,
                                    )}
                                </Toggler>
                            </select>
                            <label htmlFor="studentGroupIdSelect">Wybierz grupę</label>
                        </div>
                    </div>
                </div>
            </div>

            {/* DATE RANGE */}
            <div>{formatDate(startDate)} :: {formatDate(endDate)}</div>

            {/* BUTTONS */}
            <div className="d-flex gap-2 mt-3 mb-3">
                <button className="btn btn-sm btn-secondary" onClick={() => onStartDateChanged(threeMonthsAgo)}>
                    3 miesiące temu
                </button>
                <button className="btn btn-sm btn-secondary" onClick={() => onStartDateChanged(monthAgo)}>
                    Miesiąc temu
                </button>
                <button className="btn btn-sm btn-secondary" onClick={() => onStartDateChanged(firstOfTheMonth)}>
                    Początek miesiąca
                </button>
            </div>

            {/* SELECT STUDENT GROUP */}
            <div className="d-flex flex-column">
            </div>

            {/* BAR CHART */}
            <div id="chart" className="w-75">
                <ReactApexChart options={chartState.options}
                                series={chartState.series}
                                type="bar"
                                height={350} />
            </div>
        </div>
    );
};